import request from '../utils/request'
/* 用户物种列表 */
export function queryUserSpecies(data) {
  return request({
    url: 'api/user-species/',
    method: 'get',
    params: {
      ...data
    }
  })
}
/* 添加用户物种 */
export function insertUserSpecies(data) {
  return request({
    url: 'api/user-species/',
    method: 'post',
    data
  })
}
/* 删除用户物种 */
export function deleteUserSpecies(data) {
  return request({
    url: `api/user-species/${data}/`,
    method: 'delete'
  })
}

/* 样本列表（即个体列表） */
export function querySample(data) {
  return request({
    url: 'api/samples/',
    method: 'get',
    params: {
      ...data
    }
  })
}


/* 新建样本 */
export function insertSample(data) {
  return request({
    url: 'api/samples/',
    method: 'post',
    data
  })
}


/* 数据列表 */
export function queryDataList(data) {
  return request({
    url: 'api/data/',
    method: 'get',
    params: {
      ...data
    }
  })
}

// 上传数据
export function uploadData(data) {
  return request({
    url: 'api/data/',
    method: 'post',
    timeout: 1000 * 60 * 60 * 6,
    headers: { 'Content-Type': 'multipart/form-data' },
    data
  })
}

// 下载数据
export function downloadData(data) {
  return request({
    url: 'api/data/bulk-download/',
    method: 'post',
    responseType: 'blob',
    // responseType: 'arraybuffer',
    data
  })
}

// 删除数据
export function deleteData(data) {
  return request({
    url: 'api/data/bulk-delete/',
    method: 'delete',
    data
  })
}

// 导出全部数据
export function exportTableAll(data) {
  return request({
    url: 'api/data/export/',
    method: 'get',
    responseType: 'blob',
    params: {
      ...data
    }
  })
}